@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'ComfortaaBold';
    src: url('fonts/comfortaa_bold-webfont.woff2') format('woff2'),
        url('fonts/comfortaa_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ComfortaaRegular';
    src: url('fonts/comfortaa_regular-webfont.woff2') format('woff2'),
        url('fonts/comfortaa_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedBold';
    src: url('fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedBoldItalic';
    src: url('fonts/robotocondensed-bolditalic-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedItalic';
    src: url('fonts/robotocondensed-italic-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedLight';
    src: url('fonts/robotocondensed-light-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedLightItalic';
    src: url('fonts/robotocondensed-lightitalic-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedRegular';
    src: url('fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
        url('fonts/robotocondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
