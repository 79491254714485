@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'ComfortaaBold';
    src: url(/static/media/comfortaa_bold-webfont.b3726607.woff2) format('woff2'),
        url(/static/media/comfortaa_bold-webfont.6fdb86c7.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ComfortaaRegular';
    src: url(/static/media/comfortaa_regular-webfont.9676a327.woff2) format('woff2'),
        url(/static/media/comfortaa_regular-webfont.906e9da6.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedBold';
    src: url(/static/media/robotocondensed-bold-webfont.23a0d9bf.woff2) format('woff2'),
        url(/static/media/robotocondensed-bold-webfont.f6f54fbe.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedBoldItalic';
    src: url(/static/media/robotocondensed-bolditalic-webfont.28c8df01.woff2) format('woff2'),
        url(/static/media/robotocondensed-bolditalic-webfont.c78bc10a.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedItalic';
    src: url(/static/media/robotocondensed-italic-webfont.772bb0e5.woff2) format('woff2'),
        url(/static/media/robotocondensed-italic-webfont.e2063e5a.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedLight';
    src: url(/static/media/robotocondensed-light-webfont.e05522f7.woff2) format('woff2'),
        url(/static/media/robotocondensed-light-webfont.c1c0643d.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedLightItalic';
    src: url(/static/media/robotocondensed-lightitalic-webfont.e691829c.woff2) format('woff2'),
        url(/static/media/robotocondensed-lightitalic-webfont.e78e1f7f.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensedRegular';
    src: url(/static/media/robotocondensed-regular-webfont.fbd1e701.woff2) format('woff2'),
        url(/static/media/robotocondensed-regular-webfont.8d99927b.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

